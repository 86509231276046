








import { UtilsString } from "@/utils/utils-string";
import { Component, Vue } from "vue-property-decorator";
import DraggableTestDashboard from "../Dashboard/DraggableTestDashboard.vue";

@Component({
  components: {
    DraggableTestDashboard,
  },
})
export default class dashboard_by_group extends Vue {
  created() {}

  show_btns() {
    return UtilsString.StringToboolean(this.$route.meta.show_btns);
  }
}
