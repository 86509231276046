









































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash";
import dashboardModule from "@/store/modules/dashboard-module";
import BasicGaugeConfigDisRes from "@/components/Charts/BasicGauges/BasicGaugeConfigDisRes.vue";
import BasicDatoSimpleDisRes from "@/components/Charts/BasicDatoSimpleDisRes.vue";
import LinearChartDate from "@/components/Charts/LinearChartDate.vue";
import grupo_dashboardModule from "@/store/modules/grupo_dashboard-module";
import { UtilsString } from "@/utils/utils-string";
import TablaDashboard from "@/components/Charts/TablaDashboard.vue";
import { dispositivo_dashboard } from "@/shared/dtos/dispositivo_dashboard";
import { dashboard } from "@/shared/dtos/dashboard";
import TablaDashboardPois from "@/components/Charts/TablaDashboardPois.vue";
import BasicDatoTextoIconoValor from "@/components/Charts/BasicDatoTextoIconoValor.vue";

@Component({
  components: {
    Dashboard,
    DashLayout,
    DashItem,
    BasicDatoSimpleDisRes,
    BasicGaugeConfigDisRes,
    LinearChartDate,
    TablaDashboard,
    TablaDashboardPois,
    BasicDatoTextoIconoValor,
    Xbtn: () => import("@/components/buttons/x-btn.vue"),
    BasicGaugeConfigurable: () =>
      import("@/components/Charts/BasicGauges/BasicGaugeConfigurable.vue"),
  },
})
export default class DraggableTestDashBoard extends Vue {
  @Prop() id_group!: number;
  @Prop({ default: false }) show_btns!: boolean;
  public bloqueado = true;
  public shw_code = false;
  public str_integrar = "";
  public forwardSlashRegex = /(\/)/g;
  public boton_seleccionado: dispositivo_dashboard = new dispositivo_dashboard();

  public async created() {
    if (dashboardModule.primera_vez_entrando) {
      dashboardModule.set_url_api();
      dashboardModule.set_primeravez(false);
    }
    this.operativa();

    if (
      this.dlayouts.length > 0 &&
      dashboardModule.last_group === this.id_group
    ) {
      dashboardModule.getdashboarddashby_cache_public_id_group_silecioso(
        this.id_group
      );
    } else {
      dashboardModule.getdashboarddashby_cache_public_id_group(this.id_group);
    }
    dashboardModule.set_ultimo_grupo_visto(this.id_group);
    //dashboardModule.getdashboarddashby_public_id_group(this.id_group);
    if (grupo_dashboardModule.grupo_dashboards.length <= 0) {
      await grupo_dashboardModule.getgrupo_dashboards_public();
    }
    var hosting = window.location.href.toString().split("/");
    var str = "";
    var other_str = "";
    var acumular = true;
    for (let i = 0; i < hosting.length; i++) {
      if (hosting[i] !== "#" && acumular) {
        str =
          UtilsString.ValueOf(str) +
          UtilsString.ValueOf(hosting[i] === "" ? "/" : "/" + hosting[i]);
      } else {
        acumular = false;
        other_str =
          UtilsString.ValueOf(other_str) +
          UtilsString.ValueOf(hosting[i] === "" ? "/" : "/" + hosting[i]);
      }
    }
    //str = UtilsString.ValueOf(str).replaceAll("/http", "http");
    str = str.replace(/(\/http)/g, "http");
    //str = str + "/#/";
    other_str = UtilsString.ValueOf(other_str).replaceAll(
      "main",
      "cuadros_de_mando"
    );

    this.str_integrar = str + other_str;
  }

  get dlayouts() {
    return dashboardModule.dashboards_drag_drop;
  }

  get dispositivos() {
    if (dashboardModule.dashboards_drag_drop.length > 0) {
      if (dashboardModule.dashboards_drag_drop[0].dispositivos.length > 0) {
        this.boton_seleccionado =
          dashboardModule.dashboards_drag_drop[0].dispositivos[0];
      }
      return dashboardModule.dashboards_drag_drop[0].dispositivos;
    }
    return [];
  }

  public name_dash() {
    return UtilsString.ValueOfValueName(
      grupo_dashboardModule.grupo_dashboards.find((x) => x.id == this.id_group),
      "nombre"
    );
  }
  public IcanShowDash(dashboard: dashboard) {
    return (
      dashboard.dispositivos.find(
        (x) => x.id_dispositivo === this.boton_seleccionado.id_dispositivo
      ) !== undefined
    );
  }
  public operativa() {}
  public edit() {
    this.bloqueado = false;
  }
  public save() {
    this.bloqueado = true;
    this.$forceUpdate();
    dashboardModule.guardar_diseno_dashboard(this.dlayouts);
  }
  code() {
    this.shw_code = true;
  }
  refrescar() {
    dashboardModule.getdashboarddashby_public_id_group(this.id_group);
  }
}
